import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { get, set } from '@ember/object';

export default Component.extend({
    store: service(),
    dialogs: service(),
    intl: service(),
    ttapi2: service(),
    error: service(),

    tagName: '',

    show_request_error: false,

    async didReceiveAttrs() {
        set(this, 'cardsPromise', this.loadCompetences());
        await this.setLastupdated();
    },

    actions: {
        add() {
            const competence = this.store.createRecord('card', {
                taxnumber: get(this, 'user.taxnumber'),
                name: get(this, 'user.firstname') + ' ' + get(this, 'user.lastname'),
                owner: 'partner',
            });
            set(this, 'editCompetence', competence);
        },

        addSame() {
            // Cannot call collector copyRecord because of JSONAPI data
            set(
                this,
                'editCompetence',
                this.store.createRecord('card', this.editCompetence.toJSON()),
            );
        },

        async reload() {
            set(this, 'cardsPromise', this.loadCompetences());
        },

        async delete(record) {
            if (await this.dialogs.confirm(this.intl.t('general.remove_row')))
                record.destroyRecord();
        },

        async update() {
            set(this, 'updating', true);
            try {
                const userCountry = get(this, 'user.homecountry') || 'FI';
                const empCountry = get(this, 'user.employer.emp_country') || 'FI';

                await this.ttapi2.request(
                    'cardregister/updateOnePerson?' +
                    'taxnumber=' + get(this, 'user.taxnumber') +
                    '&userCountry=' + userCountry +
                    '&vatin=' + get(this, 'user.employer.emp_id') +
                    '&empCountry=' + empCountry,

                    {
                        dataType: 'json',
                        contentType: 'application/json',
                        method: 'POST',
                    },
                );
                this.send('reload');
                await this.setLastupdated();
            } catch (e) {
                // we only need payload from error to get the error message
                // eslint-disable-next-line no-unused-vars
                const { response, jqXHR, payload } = e;
                this.error.notify({
                    payload: {
                        message: this.intl.t(
                            `cardregister.errors.${payload ? payload : 'unknown'}`,
                        ),
                    },
                });
            } finally {
                set(this, 'updating', false);
            }
        },
    },

    async loadCompetences() {
        let start = moment();

        // sometimes employer is not fully loaded so fetch emp_id if missing
        if (!get(this, 'user.employer.emp_id') && get(this, 'user.id'))
            await this.store.query('user', {
                id: get(this, 'user.id'),
                sideload: ['employer.emp_id', 'employer.emp_country'],
            });
        if (get(this, 'user.taxnumber')) {
            const userCountry = get(this, 'user.homecountry') || 'FI';
            const empCountry = get(this, 'user.employer.emp_country') || 'FI';

            let resp = await this.store.query('card', {
                taxnumber: get(this, 'user.taxnumber'),
                userCountry,
                vatin: get(this, 'user.employer.emp_id'),
                empCountry,
            });
            var stop = moment();
            if (stop.diff(start) / 1000 > 8) {
                this.show_request_error = true;
            }
            return resp;
        }
    },

    async setLastupdated() {
        if (!get(this, 'user.taxnumber')) return set(this, 'lastupdated', '-');
        const res = await this.ttapi2.request(
            'cardregister/getCardUpdaters?taxnumber=' + get(this, 'user.taxnumber'),
            {
                dataType: 'json',
                contentType: 'application/json',
            },
        );
        const updater = res.data?.firstObject;
        if (!updater) set(this, 'lastupdated', '-');
        else
            set(
                this,
                'lastupdated',
                moment(updater.attributes.updated).format('DD.MM.YYYY HH:mm:ss'),
            );
    },
});
