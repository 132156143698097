import { hash } from 'rsvp';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { get } from '@ember/object';
import moment from 'moment'; // Make sure you have moment imported
import initRoute from '../../../mixins/init-route';

export default Route.extend(initRoute, {
    ttapi: service(),
    flags: service(),
    collector: service('collector-service'),
    queryParams: {
        date: { refreshModel: true },
        page: { refreshModel: true },
        user: { refreshModel: true },
        employer: { refreshModel: true },
        debug: { refreshModel: true }
    },

  async model(params) {
    if (get(this, 'flags').test('bank-v2')) {
      return await this.newModel(params);
    } else {
      return await this.oldModel(params);
    }
  },

  actions: {
    refreshModel() {
      this.refresh();
    },
  },

  /**
   * If bank-v2 is active, we first request the “usersWithBanks” data,
   * check if we have a date param. If not, and data.firstDate is present,
   * we set the param to "firstDate_currentDate" and transition, causing
   * the route to reload with that date range.
   */
  async newModel(params) {
    // Build up the query string for the request:
    const baseQuery = [
      'limit=25',
      'offset=' + (params.page ? (params.page - 1) * 25 : 0),
    ];
    if (params.date) baseQuery.push('date=' + params.date);
    if (params.user) baseQuery.push('id=' + params.user);
    if (params.employer) baseQuery.push('employer=' + params.employer);
    if (params.debug) baseQuery.push('debug=' + params.debug);

    let url = 'api/usersWithBanks';
    if (baseQuery.length) {
      url += '?' + baseQuery.join('&');
    }

    if (params.user || params.employer) {
        // If user params user or employer is set, we need clear the page param and offset it to 0
        params.page = 1;
        params.offset = 0;
    }

    // Fetch the main data from ttapi:
    const data = await get(this, 'ttapi').request(url);

    // If user has no date param set yet, but the backend gives us “firstDate”,
    // set the queryParam to firstDate_today’sDate, and cause a route reload.
    if (!params.date && data.firstDate) {
      let startDate = data.firstDate;              // e.g. "2020-01-15"
      let endDate   = moment().format('YYYY-MM-DD'); // e.g. "2025-01-24"
      let newDate   = `${startDate}_${endDate}`;

      // Transition with the new date param. The route’s model hook will fire again.
      this.transitionTo({
        queryParams: {
          ...params, // keep existing page/user/employer if set
          date: newDate
        },
      });
      return; // return here so we do not continue below
    }

    // If the user already has a date param or no firstDate was found, just proceed:
    let banks = this.store.findAll('bank', { include: true });

    return hash({
      users: data,
      accounts: await banks,
      pageCount: Math.ceil(data.count / 25),
      firstDate: data.firstDate,
    });
  },

    async oldModel(params) {
        const cols = ['user', 'employer'];
        const absencetypes = [];
        let model = this.store.findAll('bank', { include: true });
        let self = this;
        const data = await model;
        data.forEach((bank) => {
            cols.push(get(bank, 'column'));
            if (get(bank, 'absencetype')) absencetypes.push(get(bank, 'absencetype.id'));
        });
        const rowsPerPage = 25;
        params.limit = 25;
        if (params.page) {
            params.offset = (params.page - 1) * rowsPerPage;
        } else {
            params.offset = 0;
        }
        params.date = params.date
            ? params.date
            : moment().subtract(6, 'year').format('YYYY-MM-DD') +
              '_' +
              moment().format('YYYY-MM-DD');
        let worktimeQueryParams = {
            date: params.date,
            groupBy: 'user',
            cols: cols.join(','),
            sideload: true,
            limit: params.limit,
            offset: params.offset,
        };
        let absenceQueryParams = {
            startdate: params.date,
            groupBy: 'user,abcensetype',
            sideload: true,
            cols: 'user,abcensetype,absence_hours',
            abcensetype: absencetypes.join('||'),
            limit: 'all',
        };
        // add user-queryparameter only if user has filtered report based on user
        // empty user-parameter on capi-request returns empty results for userlevel != 5
        if (params.user) {
            worktimeQueryParams.user = params.user;
            absenceQueryParams.user = params.user;
        }
        if (get(self, 'collector').testNeedsOne('employer') && params.employer) {
            worktimeQueryParams.employer = params.employer;
            absenceQueryParams.employer = params.employer;
        }

        return hash({
            rows: self.store.query('worktime', worktimeQueryParams),
            absences: get(self, 'collector').testNeedsOne('abcense')
                ? self.store.query('abcense', absenceQueryParams)
                : [],
            accounts: model,
            year: moment(params.date?.split('_')[1]).format('YYYY'),
            lastyear: moment(params.date?.split('_')[1]).subtract(1, 'year').format('YYYY'),
        });
    },
});
