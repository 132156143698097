import { A } from '@ember/array';
import EmberObject, { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { getOwner } from '@ember/application';
import { typeOf, isEmpty } from '@ember/utils';

export default Controller.extend({
    store: service(),
    Collector: service('collector-service'),
    fileExports: service('file-exports'),
    ttapi: service(),
    intl: service(),
    flags: service(),
    session: service(),
    dialogs: service(),

    selectedYear: null,

    user: null,
    employer: null,
    page: 1,
    ignoreWhereNoDaysLeft: false,

    results: null,

    error: false,
    saving: false,

    init() {
        this._super();
        this.queryParams = [
            'page',
            'employer',
            'user',
            'selectedYear',
            'group',
            'worktimegroup',
            'superior',
        ];
        this.columnList = [
            'lastname',
            'firstname',
            'allHolidayPays',
            'usedHolidayPays',
            'bookedHolidayPays',
            'availableHolidayPays',
        ];
        this.rowActions = [
            { action: 'showDetails', icon: 'move move-Search', text: this.intl.t('general.edit') },
        ];

        if (moment().month() >= 3) {
            set(this, 'selectedYear', '' + moment().year());
        } else {
            set(this, 'selectedYear', '' + (moment().year() - 1));
        }
    },

    holidays: alias('model.data'),
    params: alias('model.params'),

    filters: computed(function () {
        return A([
            EmberObject.create({
                field: 'employer',
                type: 'database',
                form: 'user',
                name: 'employer',
                value: this.employer,
            }),
            EmberObject.create({
                field: 'user',
                type: 'database',
                form: 'worktime',
                name: 'user',
                value: this.user,
            }),
            EmberObject.create({
                field: 'group',
                type: 'database',
                form: 'user',
                name: 'group',
                value: this.group,
            }),
            EmberObject.create({
                field: 'worktimegroup',
                type: 'database',
                form: 'user',
                name: 'worktimegroup',
                value: this.worktimegroup,
            }),
            EmberObject.create({
                field: 'superior',
                type: 'database',
                form: 'user',
                name: 'superior',
                value: this.superior,
            }),
        ]);
    }),

    dateStart: computed('selectedYear', function () {
        if (get(this.store.peekAll('companyInfo'), 'firstObject.country') === 'se')
            return get(this, 'selectedYear') + '-04-01';
        return get(this, 'selectedYear') + '-05-02';
    }),

    years: computed(function () {
        const ret = [];
        let nextHolidaySeason = moment().year();

        for (
            let start = nextHolidaySeason,
                year = start,
                end = nextHolidaySeason - 3,
                asc = start <= end;
            asc ? year <= end : year >= end;
            asc ? year++ : year--
        ) {
            ret.push({
                value: '' + year,
                text: this.intl.t('holiday_season') + ' (' + year + ')',
            });
        }

        return ret;
    }),

    translated_columns: computed(function () {
        const translatedColumns = [
            'report.user.lastname',
            'report.user.firstname',
            'report.holidays.allHolidayPays',
            'report.holidays.usedHolidayPays',
            'report.holidays.bookedHolidayPays',
            'report.holidays.availableHolidayPays',
        ];
        return translatedColumns.map((item) => {
            return this.intl.t(item);
        });
    }),

    pageCount: computed('holidays.count', function () {
        return Math.ceil(get(this, 'holidays.count') / 25);
    }),

    actions: {
        addHolidayPay() {
            set(this, 'deductsValue', true);
            set(
                this,
                'holidayPayRecord',
                this.store.createRecord('holidaypay', { date: this.years[0] }),
            );
        },

        async editHolidayPay(row) {
            let rec = this.store.peekRecord('holidaypay', row.id);
            if (!rec) rec = await this.store.findRecord('holidaypay', row.id);
            if (typeOf(rec['date']) === 'string') {
                rec['date'] = { value: rec['date'].substr(0, 4) };
            }
            set(this, 'deductsValue', !rec['deducts']);
            set(this, 'holidayPayRecord', rec);
        },

        async removeHolidayPay(row) {
            let rec = this.store.peekRecord('holidaypay', row.id);
            if (!rec) rec = await this.store.findRecord('holidaypay', row.id);
            await rec.destroyRecord();
            this.refreshReport();
        },

        onFieldChange(field, value) {
            set(this, 'holidayPayRecord.' + field, value);
        },

        filterChanged(value, fieldName) {
            set(this, 'page', 1);
            return set(this, fieldName, !value ? null : value);
        },

        setSelectedYear(value) {
            set(this, 'page', 1);
            return set(this, 'selectedYear', value);
        },

        action(action, rowid) {
            if (action === 'showDetails') {
                set(this, 'editUser', rowid);
                set(this, 'showDetails', true);
            } else if (action === 'checkedRows') {
                if (get(this, 'Collector').testNeedsOne('holidaypay@edit')) {
                    set(this, 'checkedRows', rowid);
                }
            }
        },

        async save() {
            set(this, 'saving', true);
            try {
                const year = this.holidayPayRecord['date']['value'];
                this.holidayPayRecord['date'] = this.holidayPayRecord['date']
                    ? year + '-01-01'
                    : moment().format('YYYY-MM-DD');
                this.holidayPayRecord['deducts'] = !this.deductsValue;
                if (!isEmpty(this.checkedRows)) {
                    this.checkedRows.forEach(async (row) => {
                        let record = await this.Collector.copyRecord(this.holidayPayRecord);
                        record['user'] = await this.store.peekRecord('user', row.id);
                        await record.save();
                    });
                    set(this, 'checkedRows', null);
                } else {
                    await this.holidayPayRecord.validate();
                    if (!this.holidayPayRecord.isValid) {
                        this.holidayPayRecord['date'] = { value: year };
                        return set(this, 'saving', false);
                    }
                    await this.holidayPayRecord.save();
                }
                set(this, 'saving', false);
                this.refreshReport();
            } catch (e) {
                set(this, 'saving', false);
                this.dialogs.alert(e.message);
            }
        },

        async export_csv() {
            set(this, 'showLoader', true);

            const data = await this.getExportData();
            const translated_columns = this.translated_columns;

            const columns = this.columnList.map((ext, index) => {
                return { name: translated_columns[index], ext: ext, obj: {} };
            });

            await this.fileExports.createCsv(this.form, data, columns);
            set(this, 'showLoader', false);
        },
    },

    async getExportData() {
        const url = 'api/getUsersWithHolidays';
        const params = this.model.params;
        params.limit = 'all';
        delete params.offset;
        const response = await this.Collector.ttapi({
            url: url,
            data: params,
        });

        return response.users;
    },

    refreshReport() {
        let controller = getOwner(this).lookup('controller:application');
        let route = getOwner(this).lookup('route:' + get(controller, 'currentRouteName'));
        route.refresh();
        set(this, 'holidayPayRecord', null);
        set(this, 'historyType', 'list');
    },
});
