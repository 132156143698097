/* eslint-disable */
// this was decaffenated 2022-02-25 - linting is disabled because we know that there are a lot of problems
// You can find the old coffee file from app/pods/timetracker/calendar/controller.coffee.old
// Generated by CoffeeScript 2.6.1
var TimetrackerCalendarController;

import Ember from 'ember';

import filterModel from '../../../mixins/filter-model';

import GeneralReportController from '../../../controllers/general-report';

import DS from 'ember-data';

import saveTheChildren from 'tt4/mixins/save-the-children';
import { inject as service } from '@ember/service';

TimetrackerCalendarController = GeneralReportController.extend(filterModel, saveTheChildren, {
    Collector: Ember.inject.service('collector-service'),
    dialogs: Ember.inject.service(),
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service(),
    evented: service(),
    error: Ember.inject.service(),
    surveys: Ember.inject.service(),
    form: 'worktime',
    queryParams: ['project', 'user', 'date', 'status', 'employer', 'gantt', 'page'],
    shownColumns: ['project', 'starttime', 'endtime', 'work_hours', 'task'],
    date:
        moment().startOf('week').format('YYYY-MM-DD') +
        '_' +
        moment().endOf('week').format('YYYY-MM-DD'),
    project: null,
    user: null,
    employer: null,
    userSelectorLimit: 200,
    // do not use limiting with calender
    limit: null,
    gantt: false,
    pageCount: Ember.computed('model.response.count', function () {
        return Math.ceil(Ember.get(this, 'model.response.count') / 15);
    }),
    init_functions: function () {
        this._super();

        /*
         * Listen for store events to update worktimes/absences after adding one.
         * This is needed for the calendar display to update automatically after
         * adding a new worktime/absence *when a filter is selected*.
         */
        this.evented.on('storeEvent', this, 'onStoreEvent');

        if (this.get('Collector').testNeeds(['worktime.user@hide_filter'])) {
            this.set('user', this.session.currentUser.id);
            return this.set('do_not_show_user_selector', true);
        } else {
            // pre select 1st user
            return this.get('store')
                .query('user', {
                    form: 'worktime',
                    field: 'user',
                    order: 'lastname,firstname',
                    limit: 1,
                    offset: this.get('offset'),
                })
                .then((ret) => {
                    return Ember.run.next(this, () => {
                        if (this.get('user')) return this.set('user', this.get('user'));

                        if (
                            Ember.get(ret, 'meta.count') >= 10 &&
                            Ember.get(ret, 'meta.count') <= this.get('userSelectorLimit') &&
                            !this.get('user')
                        ) {
                            return this.set('user', Ember.get(ret, 'firstObject.id'));
                        } else {
                            return this.set('do_not_show_user_selector', true);
                        }
                    });
                });
        }
    },
    // Fetches data based on the currently selected filters
    async fetchFilteredData(modelName) {
        /*
         * Map model properties;
         * dataProperties: the data properties in the relevant model
         * modelProperty: the property to set the fetched data to
         */

        const properties = {
            worktime: {
                dataProperties: {
                    date: 'date',
                    user: 'user',
                    employer: 'employer',
                    project: 'project',
                },
                modelProperty: 'filteredModel',
            },
            abcense: {
                dataProperties: {
                    date: 'startdate_enddate',
                    user: 'user',
                    employer: 'employer',
                },
                modelProperty: 'filteredAbsences',
            }
        }

        try {
            const query = {
                sideload: true,
            };

            for (const dataPropertyKey in properties[modelName].dataProperties) {
                const dataPropertyValue = properties[modelName].dataProperties[dataPropertyKey];
                query[dataPropertyValue] = this.get(dataPropertyKey);
            }

            const data = await this.store.query(modelName, query);
            this.set(properties[modelName].modelProperty, data);
        } catch (error) {
            console.error('Error fetching updated data:', error);
        }
    },
    async onStoreEvent(params) {
        if (params.type === 'worktime' || params.type === 'abcense') {
            // Refetch and update data after adding a new worktime/absence
            await this.fetchFilteredData(params.type);
        }
    },
    //hideMoreFilters: true
    filters: Ember.computed(function () {
        return Em.A([
            Em.Object.create(
                {
                    field: 'date',
                },
                {
                    wagePeriods: true,
                },
            ),
            Em.Object.create({
                field: 'project',
            }),
            Em.Object.create({
                field: 'user',
                singleselect: true,
            }),
            Em.Object.create({
                field: 'employer',
            }),
        ]);
    }),
    addOptions: Ember.computed(function () {
        var c, options;
        options = [];
        c = this.get('Collector');
        // show worktime-adding always to users that can add worktime to other users
        if (
            c.testNeeds([
                'worktime',
                '!products.gps_force||!worktime.user@hide',
                '!worktime.starttime@disabled',
                '!worktime@errors',
            ])
        ) {
            options.push('worktime');
        }
        if (c.testNeeds(['abcense', '!abcense@errors'])) {
            options.push('abcense');
        }
        return options;
    }),
    absenceColorFrom: ['abcensetype.id', 'user.firstname', 'user.lastname'],
    multiDayAbsenceFields: Ember.computed(function () {
        return this.get('Collector').fieldArray('abcense', ['user', 'abcensetype', 'description']);
    }),
    oneDayAbsenceFields: Ember.computed(function () {
        var c, col_arr, fields;
        c = this.get('Collector');
        col_arr = ['abcensetype', 'user', 'starttime', 'endtime', 'absence_hours', 'description'];
        fields = c.fieldArray('abcense', col_arr);
        return fields;
    }),
    allAbsences: Ember.computed(function () {
        if (!this.get('Collector').testNeedsOne('abcense')) {
            return [];
        }
        return this.get('store').peekAll('abcense');
    }),
    filteredAbsences: Ember.computed('allAbsences.[]', 'filters.@each.value', function () {
        var filterArr;
        if (
            !this.get('Collector').testNeedsOne('abcense.project') &&
            Ember.isPresent(this.get('project'))
        ) {
            return [];
        }
        filterArr = ['page', 'order', 'date'];
        if (!this.get('Collector').testNeedsOne('abcense.project')) {
            filterArr.push('project');
        }
        return this._filterThese(this.get('allAbsences'), filterArr);
    }),
    filteredAbsencesOneDay: Ember.computed(
        'filteredAbsences.[]',
        'filteredAbsences.@each.startdate',
        'filteredAbsences.@each.enddate',
        function () {
            var model;
            model = this.get('filteredAbsences');
            return model.filter(function (item) {
                if (item.get('enddate') === null || item.get('enddate') === item.get('startdate')) {
                    return true;
                }
                return false;
            });
        },
    ),
    filteredAbsencesManyDays: Ember.computed(
        'filteredAbsences.[]',
        'filteredAbsences.@each.startdate',
        'filteredAbsences.@each.enddate',
        function () {
            var model;
            model = this.get('filteredAbsences').sortBy('startdate');
            return model.filter(function (item) {
                if (item.get('enddate') !== null && item.get('enddate') !== item.get('startdate')) {
                    return true;
                }
                return false;
            });
        },
    ),
    sumsByUser: Ember.computed(
        'date',
        'filteredModel.@each.isNew',
        'filteredAbsences.@each.isNew',
        function () {
            var absences,
                absentDays,
                c,
                enddate,
                startdate,
                store,
                sum,
                sumArray,
                sums,
                userId,
                workDays,
                worktimes;
            if (Ember.isPresent(this.get('user'))) {
                return;
            }
            worktimes = this.get('filteredModel');
            absences = this.get('filteredAbsences');
            c = this.get('Collector');
            [startdate, enddate] = this.get('date').split('_');
            store = this.get('store');
            sums = {};
            if (c.testNeedsOne('worktime')) {
                workDays = worktimes.reduce(
                    (obj, worktime) => {
                        var isWorkDateAdded, workLog;
                        if (worktime.get('isNew')) {
                            return obj;
                        }
                        if (!moment(worktime.get('date')).isBetween(startdate, enddate, null, [])) {
                            return obj;
                        }
                        if (sums[worktime.get('user.id')] != null) {
                            sums[worktime.get('user.id')].wt += worktime.get('work_hours');
                        } else {
                            sums[worktime.get('user.id')] = {
                                wt: worktime.get('work_hours'),
                                ab: 0,
                            };
                        }
                        workLog = {
                            id: worktime.get('user.id'),
                            date: worktime.get('date'),
                        };
                        isWorkDateAdded = obj.elementsIterated.find((item) => {
                            return item.id === workLog.id && item.date === workLog.date;
                        });
                        if (isWorkDateAdded) {
                            return obj;
                        } else {
                            obj.elementsIterated.push(workLog);
                            obj[worktime.get('user.id')] = obj[worktime.get('user.id')]
                                ? ++obj[worktime.get('user.id')]
                                : 1;
                            return obj;
                        }
                    },
                    {
                        elementsIterated: [],
                    },
                );
            }
            if (c.testNeedsOne('abcense')) {
                absentDays = absences.reduce(
                    (obj, absence) => {
                        var absenceLog, isAbsenceAdded;
                        if (absence.get('isNew')) {
                            return obj;
                        }
                        if (
                            !moment(absence.get('startdate')).isBetween(
                                startdate,
                                enddate,
                                null,
                                [],
                            )
                        ) {
                            return obj;
                        }
                        if (sums[absence.get('user.id')] != null) {
                            sums[absence.get('user.id')].ab += absence.get('absence_hours');
                        } else {
                            sums[absence.get('user.id')] = {
                                ab: absence.get('absence_hours'),
                                wt: 0,
                            };
                        }
                        absenceLog = {
                            id: absence.get('user.id'),
                            startdate: absence.get('startdate'),
                            enddate: absence.get('enddate'),
                        };
                        isAbsenceAdded = obj.elementsIterated.find((item) => {
                            return item.id === absenceLog.id && item.enddate === absenceLog.enddate;
                        });
                        if (isAbsenceAdded) {
                            return obj;
                        } else {
                            obj.elementsIterated.push(absenceLog);
                            if (obj[absence.get('user.id')] != null) {
                                obj[absence.get('user.id')] += absence.get('dayamount') || 1;
                            } else {
                                obj[absence.get('user.id')] = absence.get('dayamount') || 1;
                            }
                            return obj;
                        }
                    },
                    {
                        elementsIterated: [],
                    },
                );
            }
            sumArray = [];
            for (userId in sums) {
                sum = sums[userId];
                sumArray.push({
                    user: store.peekRecord('user', userId),
                    worktime: sum.wt,
                    absence: sum.ab,
                    workdays: workDays ? workDays[userId] : null,
                    absentdays: absentDays ? absentDays[userId] : null,
                });
            }
            return Ember.A(sumArray);
        },
    ),
    userSums: Em.computed(
        'date',
        'filteredModel.@each.isSaving',
        'filteredAbsences.@each.isSaving',
        function () {
            var absences, worktimes;
            worktimes = this.get('filteredModel');
            absences = this.get('filteredAbsences');
            if (
                worktimes.any((item) => {
                    return item.get('isSaving') || item.get('isNew');
                }) ||
                absences.any((item) => {
                    return item.get('isSaving') || item.get('isNew');
                })
            ) {
                return;
            }
            if (Em.isPresent(this.get('user'))) {
                //return @fetchSums()
                return DS.PromiseObject.create({
                    promise: this.fetchSums(),
                });
            }
        },
    ),
    showWarning: Ember.computed('filteredModel', 'filteredAbsences', function () {
        if (this.get('filteredModel.length') >= 100 || this.get('filteredAbsences.length') >= 100) {
            return true;
        } else {
            return false;
        }
    }),
    fetchSums: function () {
        var abparams, c, filters, params;
        c = this.get('Collector');
        filters = this.get('filters');
        params = {
            groupBy: 'user',
        };
        abparams = {
            groupBy: 'user',
        };
        filters.forEach((filter) => {
            var split, v;
            v = Ember.get(filter, 'value');
            if (Ember.isPresent(v)) {
                if (Ember.get(filter, 'field').includes('-')) {
                    split = Ember.get(filter, 'field').split('-');
                    if (Ember.isEmpty(params[split[0]])) {
                        return (params[split[0]] = {
                            [`${split[1]}`]: v,
                        });
                    }
                } else {
                    if (Ember.get(filter, 'field') === 'date') {
                        abparams.startdate = v;
                    } else if (c.fieldExists('abcense', Ember.get(filter, 'field'))) {
                        abparams[Ember.get(filter, 'field')] = v;
                    }
                    if (c.fieldExists('worktime', Ember.get(filter, 'field'))) {
                        return (params[Ember.get(filter, 'field')] = v);
                    }
                }
            }
        });
        return new Ember.RSVP.Promise((resolve, reject) => {
            var absences, worktimes;
            if (c.testNeedsOne('worktime')) {
                worktimes = this.get('Collector').ajax({
                    url: 'worktimes',
                    data: params,
                });
            }
            if (
                c.testNeedsOne('abcense') &&
                (Ember.isEmpty(this.get('project')) || c.testNeedsOne('abcense.project'))
            ) {
                absences = this.get('Collector').ajax({
                    url: 'abcenses',
                    data: abparams,
                });
            }
            return Ember.RSVP.Promise.all([worktimes, absences]).then((data) => {
                var ret;
                ret = {};
                if (data[0]) {
                    ret['worktimes'] = data[0]['worktimes'][0];
                }
                if (data[1]) {
                    ret['absences'] = data[1]['abcenses'][0];
                }
                return resolve(ret);
            });
        });
    },
    skipSumFieldsWorktimes: ['id', 'user'],
    skipSumFieldsAbsences: ['startdate', 'enddate', 'id', 'user'],
    canAdd: function (type, date) {
        var c;
        if (type === 'worktime') {
            c = this.get('Collector');
            return c.canAddWorktime(date);
        } else {
            return true;
        }
    },
    statusTypes: Em.computed('form', function () {
        var c, field, intl, option, options;
        if (!this.get('form')) {
            return [];
        }
        c = this.get('Collector');
        intl = this.get('intl');
        field = c.field(this.get('form'), 'status');
        options = (function () {
            var i, len, ref, results;
            ref = field.options;
            results = [];
            for (i = 0, len = ref.length; i < len; i++) {
                option = ref[i];
                if (intl.exists('status.' + option)) {
                    results.push({
                        id: option,
                        name: intl.t('calendar.' + option + '_all_rows'),
                    });
                } else {
                    results.push({
                        id: option,
                        name: intl.t('calendar.change_status') + option,
                    });
                }
            }
            return results;
        })();
        return options;
    }),
    saldosInUse: Ember.computed(function () {
        return this.get('Collector').testNeeds(['products.saldo']);
    }),
    currentSaldo: Ember.computed(
        'filteredModel.@each.isSaving',
        'filteredAbsences.@each.isSaving',
        function () {
            return Ember.ObjectProxy.extend(Ember.PromiseProxyMixin).create({
                promise: new Ember.RSVP.Promise((resolve) => {
                    return this.get('Collector')
                        .ttapi({
                            url: 'saldo/get_saldo?userid=' + this.get('user'),
                        })
                        .then((back) => {
                            return resolve({
                                value: back['saldo'] / 60,
                                from: back['from'],
                                to: back['to'],
                            });
                        });
                }),
            });
        },
    ),
    saldoList: Em.computed(
        'date',
        'filteredModel.@each.isSaving',
        'filteredAbsences.@each.isSaving',
        function () {
            var saldoEnddate, saldoStartdate;
            if (!this.get('Collector').testNeeds(['products.saldo'])) {
                return;
            }
            saldoStartdate = this.get('date').split('_')[0];
            saldoEnddate = this.get('date').split('_')[1];
            return Ember.ObjectProxy.extend(Ember.PromiseProxyMixin).create({
                promise: new Ember.RSVP.Promise((resolve) => {
                    return this.get('Collector')
                        .ttapi({
                            url:
                                'saldo/get_saldo_list?startdate=' +
                                saldoStartdate +
                                '&enddate=' +
                                saldoEnddate +
                                '&userid=' +
                                this.get('user'),
                        })
                        .then((back) => {
                            return resolve(back);
                        });
                }),
            });
        },
    ),
    actions: {
        filterChanged: async function (value, field) {
            if (!field || !value) return;

            // For object type fields we need to get the id,
            // for string type fields we can set the value directly
            if (typeof(value) == "object") {
                this.set(field, value.get('id'))
            } else if (typeof(value) == "string") {
                this.set(field, value);
            }

            // After setting the filter value, refetch data to update the view
            await Promise.all([
                this.fetchFilteredData('worktime'),
                this.fetchFilteredData('abcense')
            ]);
        },
        addNew: function (day, type) {
            var params;
            window.scrollTo(0, 0);
            params = {};
            if (type === 'abcense') {
                params.startdate = day;
                params.enddate = day;
            } else {
                params.date = day;
            }
            if (this.get('user')) {
                params.user = this.get('store').peekRecord('user', this.get('user'));
            }
            if (this.get('project')) {
                params.project = this.get('store').peekRecord('project', this.get('project'));
            }
            this.get('store').createRecord(type, params);
            return this.transitionToRoute({
                queryParams: {
                    modal: [type, 'new'],
                },
            });
        },
        eventDropped: function (day, event) {
            var c, dropped_record, id, model, record;
            c = this.get('Collector');
            [id, model] = event.split(':');
            dropped_record = this.get('store').peekRecord(model, id);
            if (dropped_record.get('date') === day || dropped_record.get('startdate') === day) {
                return;
            }
            if (!this.canAdd(model, day)) {
                this.get('dialogs').alert(this.get('intl').t('calendar.add.error.' + model));
                return;
            }
            record = c.copyRecord(dropped_record);
            Ember.set(record, 'row_info', {
                privilege_edit: true,
                privilege_remove: true,
            });
            if (model === 'abcense') {
                record.set('startdate', day);
                record.set('enddate', day);
            } else {
                record.set('date', day);
            }
            return this.save_record(record)
                .then((row) => {
                    return row.reload();
                })
                .catch((error) => {
                    record.destroyRecord();
                    return this.get('error').notify(error);
                });
        },
        openEditor: function (row) {
            var params;
            window.scrollTo(0, 0);
            params = [row.get('constructor.modelName'), row.get('id')];
            if (row.get('row_info.privilege_remove') !== void 0 && !row.get('isEditable')) {
                params.push('show');
            }
            return this.transitionToRoute({
                queryParams: {
                    modal: params,
                },
            });
        },
        remove: function (record) {
            return this.get('dialogs')
                .confirm(this.get('intl').t('general.remove_row'))
                .then(function (con) {
                    if (con) {
                        return record.destroyRecord();
                    }
                });
        },
        catchActions: function (action, params) {
            var c, cols, fields;
            if (action === 'fieldsUpdate') {
                c = this.get('Collector');
                cols = [];
                params.forEach((col) => {
                    return cols.push(col.ext);
                });
                fields = c.fieldArray(this.get('form'), cols);
                return this.set('worktimeFields', fields);
            }
        },
        userChanged: async function (userid) {
            /*
             * Note that this is called when the user is changed
             * using the user selector, or by using the dropdown.
             * When the user dropdown is used, the user selector is also
             * updated, but not vice versa. However, the data is refetched
             * and the calendar view updated in both cases, so the most recent
             * change is always reflected in the view, regardless of which is used.
             */

            // Update user by calling filterChanged
            await this.send('filterChanged', userid, 'user');
        },
        changeStatus: function (status) {
            return this.get('dialogs')
                .confirm(this.get('intl').t('calendar.change_' + status + '_all_confirm'))
                .then((con) => {
                    var absences, filteredStartDate, intl, worktimes;
                    if (con) {
                        worktimes = this.get('filteredModel');
                        absences = this.get('filteredAbsences');
                        filteredStartDate = this.get('date').split('_')[0];
                        absences = absences.filter((item) => {
                            var endDate;
                            endDate = item.enddate;
                            return endDate >= filteredStartDate;
                        });
                        intl = this.get('intl');
                        worktimes.forEach((worktime) => {
                            worktime.set('status', status);
                            return worktime.save().catch((e) => {
                                var error_string;
                                console.log(e);
                                if (worktime.get('isError')) {
                                    error_string = e.errors[0].error_msg;
                                    if (
                                        intl.exists(
                                            'validation_error.' +
                                                Ember.String.underscore(error_string),
                                        )
                                    ) {
                                        error_string = intl.t(
                                            'validation_error.' +
                                                Ember.String.underscore(error_string),
                                        );
                                    } else if (
                                        intl.exists('validation_error.' + e.errors[0].error)
                                    ) {
                                        error_string = intl.t(
                                            'validation_error.' + e.errors[0].error,
                                        );
                                    }
                                    this.get('notifications').error(error_string, {
                                        autoClear: true,
                                    });
                                    return worktime.rollbackAttributes();
                                }
                            });
                        });
                        absences.forEach((absence) => {
                            absence.set('status', status);
                            return absence.save().catch((e) => {
                                var error_string;
                                if (absence.get('isError')) {
                                    error_string = e.errors[0].error_msg;
                                    if (
                                        intl.exists(
                                            'validation_error.' +
                                                Ember.String.underscore(error_string),
                                        )
                                    ) {
                                        error_string = intl.t(
                                            'validation_error.' +
                                                Ember.String.underscore(error_string),
                                        );
                                    } else if (
                                        intl.exists('validation_error.' + e.errors[0].error)
                                    ) {
                                        error_string = intl.t(
                                            'validation_error.' + e.errors[0].error,
                                        );
                                    }
                                    this.get('notifications').error(error_string, {
                                        autoClear: true,
                                    });
                                    return absence.rollbackAttributes();
                                }
                            });
                        });
                        if (
                            status === 'approved' &&
                            this.get('Collector').testNeeds(['products.vismanet||products.severa'])
                        ) {
                            return this.set('showFastExporter', true);
                        }

                        if (status === 'approved') this.surveys.initializeAndRun('row_approved'); // show wootric CES-survey after row approved
                    }
                });
        },
    },
});

export default TimetrackerCalendarController;
