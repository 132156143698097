/*eslint-disable ember/named-functions-in-promises */
import { A } from '@ember/array';

import { Promise } from 'rsvp';
import EmberObject, { computed, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';

export default Controller.extend({
    store: service(),
    Collector: service('collector-service'),
    ttapi: service(),
    intl: service(),
    notifications: service(),
    session: service(),
    fileExports: service('file-exports'),

    selectedYears: null,
    importYear: null,

    user: null,
    employer: null,
    page: 1,

    results: null,

    error: false,

    selectedDelimiter: ';',

    delimiterOptions: null,
    init() {
        this._super();
        this.queryParams = [
            'page',
            'employer',
            'user',
            'selectedYears',
            'group',
            'worktimegroup',
            'superior',
            'used_saturdays',
        ];
        this.columnList = [
            'lastname',
            'firstname',
            'holidayYear',
            'allDays',
            'usedDays',
            'bookedDays',
            'daysLeft',
            'used_saturdays',
        ];
        this.selectedYears = moment().month() >= 3 ? [moment().year()] : [moment().year() - 1];
        return (this.delimiterOptions = [
            { id: ';', name: ';' },
            { id: ',', name: ',' },
        ]);
    },

    holidaysAlias: alias('model.data'),

    autoAssumedHolidays: computed(function () {
        if (get(this.store.peekAll('companyInfo'), 'firstObject.country') === 'se') return 25;
        else return 30;
    }),

    showRecalculateButton: computed(function () {
        if (
            this.session.session.content.authenticated.scope ||
            this.Collector.testNeeds(['development'])
        ) {
            return true;
        } else return false;
    }),

    rowActions: computed('intl', function () {
        let arr = [];

        if (get(this, 'Collector').testNeeds(['userlevel=5'])) {
            arr.pushObject({
                action: 'editRow',
                icon: 'move move-Pencil',
                text: get(this, 'intl').t('general.edit'),
            });
        }

        arr.pushObject({
            action: 'showDetails',
            icon: 'move move-Search',
            text: get(this, 'intl').t('general.edit'),
        });

        return arr;
    }),

    filters: computed(function () {
        return A([
            EmberObject.create({
                field: 'employer',
                type: 'database',
                form: 'user',
                name: 'employer',
                value: this.employer,
            }),
            EmberObject.create({
                field: 'user',
                type: 'database',
                form: 'worktime',
                name: 'user',
                value: this.user,
            }),
            EmberObject.create({
                field: 'group',
                type: 'database',
                form: 'user',
                name: 'group',
                value: this.group,
            }),
            EmberObject.create({
                field: 'worktimegroup',
                type: 'database',
                form: 'user',
                name: 'worktimegroup',
                value: this.worktimegroup,
            }),
            EmberObject.create({
                field: 'superior',
                type: 'database',
                form: 'user',
                name: 'superior',
                value: this.superior,
            }),
        ]);
    }),

    importField: computed('holidaysAlias.filename', function () {
        const field = get(this, 'Collector').field('import', 'filename');
        field.value = get(this, 'holidaysAlias.filename');
        return field;
    }),

    data: computed('holidaysAlias.users', 'hideEmptyOnes', function () {
        const users = get(this, 'holidaysAlias.users');
        return new Promise((resolve) => {
            return get(this, 'store')
                .findAll('holiday')
                .then((holidays) => {
                    const usersWithHolidays = new Set();
                    holidays.forEach((holiday) => {
                        return usersWithHolidays.add(get(holiday, 'id').toString());
                    });
                    return resolve(usersWithHolidays);
                });
        }).then(() => {
            return this.hideEmptyOnes
                ? users.sortBy('lastname').filter((user) => user.daysLeft > 0)
                : users.sortBy('lastname');
            
        });
    }),

    years: computed(function () {
        const ret = [];
        const holidaySeasonChangeDate = moment().startOf('year').add({ days: 1, months: 4 });
        if (moment().isSameOrAfter(holidaySeasonChangeDate, 'day')) {
            set(this, 'nextHolidaySeason', moment().year()); //eslint-disable-line
        } else {
            set(this, 'nextHolidaySeason', moment().year() - 1); //eslint-disable-line
        }

        for (
            let start = get(this, 'nextHolidaySeason'),
                year = start + 1,
                end = get(this, 'nextHolidaySeason') - 3,
                asc = start <= end;
            asc ? year <= end : year >= end;
            asc ? year++ : year--
        ) {
            ret.push(year);
        }

        // If user is not main user, we don't want to show the current year
        // This filters out current year if we are before the holiday season change date
        if (!get(this, 'Collector').testNeeds(['userlevel=5'])) {
            const finalRet = [];

            // Make sure we don't show the current year if we are before the holiday season change date
            if (!moment().isSameOrAfter(holidaySeasonChangeDate, 'day')) {
                finalRet.push(...(ret.filter((year) => year !== moment().year())));
            } else {
                finalRet.push(...ret);
            }

            return finalRet;
        }

        return ret;
    }),

    translated_columns: computed(function () {
        const translatedColumns = [
            'report.user.lastname',
            'report.user.firstname',
            'report.user.holidayyear',
            'report.holidays.allDays',
            'report.hoilidays.usedDays',
            'report.holidays.bookedDays',
            'report.holidays.daysLeft',
            'report.holidays.saturdays',
        ];
        return translatedColumns.map((item) => {
            return get(this, 'intl').t(item);
        });
    }),

    pageCount: computed('holidaysAlias.count', 'hideEmptyOnes', function () {
        let count = this.hideEmptyOnes ? this.data.length : this.holidaysAlias.count;
        let perPage = this.hideEmptyOnes ? 25 : 25 * this.selectedYears.length;
        return Math.ceil(count / perPage);
    }),

    actions: {
        filterChanged(value, fieldName) {
            set(this, 'page', 1);
            return set(this, fieldName, !value ? null : value);
        },

        async recalculateHolidays(checkedRows) {
            set(this, 'loading', true);

            let ids = checkedRows.map((row) => row.id);
            ids = ids.filter((item, index) => ids.indexOf(item) === index);

            if(ids.length === 0) {
                this.notifications.error(this.intl.t('report.holidays.no_users_selected'), { autoClear: true });
                set(this, 'loading', false);
                return;
            }
            
            const url = 'api/holidays/recalculateUserHolidays';

            let responses = [];
            for(let i = 0; i < ids.length; i++) {
                let res = await this.Collector.ttapi({
                url: url,
                data: {
                    userid: ids[i],
                },
                method: 'POST',
            });
            responses.push(res);
        }

        if (responses.length > 0 && responses.filter(res => res.message).length === responses.length) {
            this.notifications.success(this.intl.t('report.holidays.recalculation_success'), { autoClear: true });
        } else {
            this.notifications.error(this.intl.t('report.holidays.recalculation_failed'), { autoClear: true });
        }
        
        set(this, 'loading', false);
        },

        setSelectedYears(value) {
            if (!value.length)
                return this.notifications.error(
                    this.intl.t('report.holidays.cannot_remove_all_seasons'),
                    { autoClear: true },
                );
            set(this, 'page', 1);
            return set(this, 'selectedYears', value.sort().reverse());
        },

        async export_csv() {
            set(this, 'showLoader', true);

            const data = await this.getExportData();
            const translated_columns = this.translated_columns;

            const columns = this.columnList.map((ext, index) => {
                return { name: translated_columns[index], ext: ext, obj: {} };
            });

            await this.fileExports.createCsv(this.form, data, columns);
            set(this, 'showLoader', false);
        },

        action(action, rowid) {
            if (action === 'showDetails') {
                set(this, 'editUser', rowid);
                set(this, 'showDetails', true);
            } else if (action === 'editRow') {
                const promises = A([]);
                set(this, 'loading', true);
                if (!Array.isArray(rowid)) {
                    set(this, 'editUsers', [rowid]);
                } else {
                    set(this, 'editUsers', rowid);
                }
                set(this, 'editUser', rowid);
                get(this, 'editUsers').forEach((row) => {
                    this.editYear = row.holidayYear + '-01-01';
                    return promises.push(
                        get(this, 'store')
                            .query('holiday', { user: row.id, year: this.editYear })
                            .then((records) => {
                                const record = get(records, 'firstObject');
                                set(this, 'holidaydays', record ? get(record, 'days') : 0);
                                return (row.currentHolidayRecord = record);
                            }),
                    );
                });

                Promise.all(promises).then(() => {
                    return set(this, 'loading', false);
                });
            } else if (action === 'checkedRows') {
                if (get(this, 'Collector').testNeedsOne('holiday@edit')) {
                    set(this, 'checkedRows', rowid);
                }
            }
        },

        async save() {
            const promises = A([]);
            set(this, 'loading', true);
            await Promise.all(
                get(this, 'editUsers').map(async (row) => {
                    let record;
                    let user = await get(this, 'store').findRecord('user', row.id);
                    if (!row.currentHolidayRecord) {
                        record = get(this, 'store').createRecord('holiday', {
                            user,
                            days: get(this, 'holidaydays'),
                            year: this.editYear,
                        });
                    } else {
                        record = row.currentHolidayRecord;
                        set(record, 'days', get(this, 'holidaydays'));
                    }

                    this.notifications.success(this.intl.t('holiday.dayamount_change'), { autoClear: true });

                    return promises.push(record.save());
                }),
            );

            return Promise.all(promises).then(() => {
                set(this, 'checkedRows', null);
                set(this, 'editUsers', null);
                set(this, 'loading', false);
                return this.send('refreshModel');
            });
        },

        openImportDialog() {
            set(this, 'record', get(this, 'store').createRecord('import'));
            set(this, 'showImportDialog', true);
            return false;
        },

        executeImport() {
            //@set('error', false)
            //if @get('record.filename').indexOf('.csv') == -1
            //    @set('error', @get('intl').t("validation.wrong_filetype"))
            //    return
            set(this, 'loading', true);
            // we have to save this record first to get the s3-url
            return get(this, 'record')
                .save()
                .then(() => {
                    const file = get(this, 'record.filename')[0];

                    const promise = get(this, 'ttapi').request('/import_holidays', {
                        type: 'POST',
                        //dataType: 'text'
                        data: {
                            import_file: file['s3url'],
                            has_header: get(this, 'has_header'),
                            delimiter: get(this, 'selectedDelimiter'),
                            year: get(this, 'importYear') || get(this, 'years.0'), // default value is first year in dropdown
                        },
                    });
                    return promise.then((results) => {
                        set(this, 'loading', false);
                        if (results.error) {
                            set(this, 'error', results.error);
                            return;
                        }
                        return set(this, 'results', results);
                    });
                });
        },

        setFile(file) {
            return set(get(this, 'record'), 'filename', file);
        },

        closeImportDialog() {
            set(this, 'showImportDialog', false);
            set(this, 'results', null);
            set(this, 'loading', false);
            set(this, 'has_header', false);
            if (get(this, 'results') || get(this, 'error')) {
                this.send('refreshModel');
            }
            return set(this, 'error', false);
        },
    },

    async getExportData() {
        const url = 'api/getMultipleSeasons';
        const params = this.model.params;
        params.limit = 'all';
        delete params.offset;
        const response = await this.Collector.ttapi({
            url: url,
            data: params,
        });

        return response.users;
    },
});
