import Component from '@glimmer/component';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';
import podNames from 'ember-component-css/pod-names';
import { tracked } from '@glimmer/tracking';
import { later, next } from '@ember/runloop';
import { A } from '@ember/array';

export default class ClockcardComponent extends Component {
    @service store;
    @service session;
    @service evented;
    @service('collector-service') collector;
    @service() notifications;
    @service intl;
    @service error;
    @service('gps2') gps;
    @service dialogs;
    @service router;
    @service analytics;

    @tracked worktimes;
    @tracked openWorktime;
    @tracked newWorktime;
    @tracked showNext;
    @tracked promise;
    @tracked showEdit = false;
    @tracked nextContainerOnTop;
    @tracked siteTooFar;
    @tracked descriptionTooLong;
    @tracked loading;
    @tracked saveAction;
    @tracked overRideEditModalTitle = false;

    get styleNamespace() {
        return podNames['clockcard'];
    }

    get workHoursToday() {
        if (!this.worktimes) return '';
        return this.worktimes.reduce((total, worktime) => total + (worktime.work_hours || 0), 0);
    }
    get startTimeToday() {
        if (!this.worktimes) return '';
        // sort worktimes by starttime and return first starttime
        return this.worktimes.filter((worktime) => worktime.starttime).sortBy('starttime')
            .firstObject?.starttime;
    }
    get pauseToday() {
        if (!this.worktimes) return '';
        // Manual pause: only add pauses for worktimes which have work_hours so it doesn't add currently running worktime's default pause
        // Multipause: use pause field normally, because multipauses are added during the open worktime
        const worktimes = this.collector.testNeeds(['products.multipause'])
            ? this.worktimes
            : this.worktimes.filter((worktime) => worktime.work_hours);
        return worktimes.reduce((total, worktime) => total + (worktime.pause / 60 || 0), 0);
    }

    get timer() {
        if (!this.openWorktime) return null;

        // on first minute seconds should start from current seconds so counter starts from 00:00:00
        const seconds =
            this.openWorktime.starttime === moment().format('HH:mm') ? moment().format('ss') : '00';
        return (
            moment(this.openWorktime.date + ' ' + this.openWorktime.starttime).format(
                'YYYY-MM-DD HH:mm:',
            ) + seconds
        );
    }

    get openPause() {
        if (!this.openWorktime?.multipause) return null;

        const pausesWithoutEnd = this.openWorktime.multipause.filter(
            (pause) => pause.start && !pause.end,
        );

        return pausesWithoutEnd?.firstObject;
    }

    get pauseTimer() {
        if (!this.openPause) return null;

        // on first minute seconds should start from current seconds so counter starts from 00:00:00
        const seconds =
            this.openPause.start === moment().format('HH:mm') ? moment().format('ss') : '00';
        return (
            moment(this.openPause.date + ' ' + this.openPause.start).format('YYYY-MM-DD HH:mm:') +
            seconds
        );
    }

    // select fields that are shown on timecard start
    // filter fields that are alwaysVisible or mandatory but not in newerShow
    // return as string ie "field1,field2"
    get showFieldsOnStart() {
        const neverShow = ['user', 'date', 'starttime', 'endtime', 'location', 'description'];
        const neverShowType = ['databasearray'];
        const fields = this.collector.fieldArray('worktime').filter((field) => {
            return (
                !neverShowType.includes(field.type) &&
                field.features.mandatory &&
                !neverShow.includes(field.name)
            );
        });

        return fields.mapBy('name').join(',');
    }

    get showFieldsOnEdit() {
        const neverShow = ['user', 'date', 'starttime', 'endtime', 'location'];
        const fields = this.collector.fieldArray('worktime').filter((field) => {
            return !neverShow.includes(field.name);
        });

        return fields.mapBy('name').join(',');
    }

    constructor() {
        super(...arguments);
        // clean new worktimes that are not saved, to prevent issue where endtime is taken from worktime form, causing an imediate save
        this.cleanNewWorktimes();

        if (
            this.collector.testNeedsOne('products.gps_force') ||
            this.collector.testNeedsOne('products.gps')
        ) {
            // if gps2 service is not started yet, start it now (this is needed because database-gps-force-field doesn't start it automatically for userlevel != 1)
            // gps2 service has a check so that it cannot be started twice
            this.gps.start();
            this.siteTooFar = true;
        }

        // eslint-disable-next-line ember/named-functions-in-promises
        this.getWorktimes().then(() => {
            this.setMySite(); // Check if this.newWorktime and session.currentMySite exist and if so, set this.newWorktime.project to session.currentMySite
        });

        this.evented.on('storeEvent', this, 'onStoreEvent');
        this.evented.on('onResumed', this, 'onResumeEvent');
        this.evented.on('gpsLockedPositionChanged', this, 'checkIfSiteTooFar');

        this.router.on('routeWillChange', (transition) => {
            if (!transition.to.find((route) => route.name === this.routeName)) {
                this.newWorktime?.rollbackAttributes();
                this.openWorktime?.rollbackAttributes();
            }
        });
        // Validate all except databasearrays, because they are hidden from start component regardless of being mandatory
        // used in worktime start validation
        this.fields = this.collector.fieldArray('worktime');
        this.validateFields = this.fields
            .filter((field) => field.type !== 'databasearray' && field.name !== 'description')
            .mapBy('name');
    }

    cleanNewWorktimes() {
        const newWorktimes = this.store.peekAll('worktime').filterBy('isNew', true);
        newWorktimes.forEach((worktime) => worktime.destroyRecord());
    }

    setMySite() {
        if (this.newWorktime && this.session && this.session.currentUser.currentMySite) {
            this.newWorktime.project = this.session.currentUser.currentMySite;
        }
    }

    willDestroy() {
        this.evented.off('storeEvent', this, 'onStoreEvent');
        this.evented.off('onResumed', this, 'onResumeEvent');
        this.evented.off('gpsLockedPositionChanged', this, 'checkIfSiteTooFar');
    }

    @action
    async start() {
        this.analytics.trackEvent({ category: 'Clockcard', action: 'Start worktime' });
        const worktime = this.newWorktime?.isDeleted
            ? this.collector.copyRecord(this.newWorktime)
            : this.newWorktime;
        if (!worktime.date) worktime.date = moment().format('YYYY-MM-DD');
        if (!worktime.user) worktime.user = this.session.currentUser;

        worktime.starttime = moment().format('HH:mm');
        if ('location' in worktime) worktime.location = await this.getLocation();
        await worktime.validate(this.validateFields);

        if (!worktime.isValid) return;

        // this.setDefaultPause(worktime)
        this.openWorktime = worktime;
        this.newWorktime = worktime;

        await this.checkIfSiteTooFar();
        if (this.siteTooFar) {
            this.openWorktime = null;
            return;
        }

        this.updateWorktimes();

        try {
            worktime.validation = 'off';
            await worktime.save();
            this.notifications.success(this.intl.t('general.saved'), { autoClear: true });
            this.evented.storeEvent('insert', 'worktime', this.openWorktime);
            if (this.args.saveAction) this.args.saveAction();
        } catch (error) {
            this.dialogs.alert(await this.error.toStringAsyncHTML(error));
            worktime.starttime = null;
            this.newWorktime = worktime;
            this.openWorktime = null;
        }
    }

    @action
    async takeABreak() {
        this.analytics.trackEvent({ category: 'Clockcard', action: 'Start break' });
        let worktime = this.openWorktime;
        this.loading = true;

        let pauseParams = {
            date: worktime.date,
            start: moment().format('HH:mm'),
            user: worktime.user,
        };
        const pauseRecord = await this.store.createRecord('multipause', pauseParams);

        try {
            await pauseRecord.save();
            if (!worktime.multipause) worktime.multipause = A([]);
            worktime.multipause.pushObject(pauseRecord);
            await worktime.save();
            this.openWorktime = worktime;
            this.notifications.success(this.intl.t('general.pause_started'), { autoClear: true });
            if (this.args.saveAction) this.args.saveAction();
        } catch (error) {
            this.dialogs.alert(await this.error.toStringAsyncHTML(error));
        } finally {
            this.loading = false;
        }
    }

    @action
    async continueWork() {
        this.analytics.trackEvent({ category: 'Clockcard', action: 'Stop break' });
        this.loading = true;
        let openPause = this.openPause;
        let worktime = this.openWorktime;

        openPause.end = moment().format('HH:mm');

        try {
            await openPause.save();
            await worktime.save();
            this.notifications.success(this.intl.t('general.pause_saved'), { autoClear: true });
        } catch (error) {
            this.dialogs.alert(await this.error.toStringAsyncHTML(error));
        } finally {
            this.loading = false;
        }
    }

    @action
    async cancelPause() {
        this.analytics.trackEvent({ category: 'Clockcard', action: 'Cancel break' });
        this.loading = true;
        let openPause = this.openPause;
        let worktime = this.openWorktime;
        try {
            worktime.multipause.removeObject(openPause);
            await openPause.destroyRecord();
            await worktime.save();
            this.notifications.info(this.intl.t('general.pause_canceled'), { autoClear: true });
        } catch (error) {
            this.dialogs.alert(await this.error.toStringAsyncHTML(error));
        } finally {
            this.loading = false;
        }
    }

    @action
    async stop(enforce_forced = false) {
        this.analytics.trackEvent({ category: 'Clockcard', action: 'Stop worktime' });
        if (this.siteTooFar) return;

        const worktime = this.openWorktime;
        await worktime.validate();

        if (
            !worktime.isValid ||
            (enforce_forced && this.collector.testNeeds(['products.force_clockcard_dialog']))
        ) {
            this.saveAction = 'stop';
            this.overRideEditModalTitle = this.intl.t('general.end_last_worktime');
            return (this.showEdit = true);
        }

        worktime.endtime = moment().format('HH:mm');
        if ('end_location' in worktime) worktime.end_location = await this.getLocation();
        await this.checkIfSiteTooFar();
        if (this.siteTooFar) {
            return;
        }
        this.openWorktime = null;

        this.updateWorktimes();
        try {
            await worktime.save();
            this.notifications.success(this.intl.t('general.saved'), { autoClear: true });
            this.evented.storeEvent('insert', 'worktime', this.openWorktime);
            if (this.args.saveAction) this.args.saveAction();
        } catch (error) {
            this.error.notify(error);
            worktime.endtime = null;
            this.openWorktime = worktime;
        }
    }

    // After clicking save in edit modal, determine next action based on saveAction
    @action
    async afterSave() {
        switch (this.saveAction) {
            case 'stop':
                await this.stop();
                break;
            case 'startnext':
                await this.startNext();
                break;
        }
    }

    @action
    openEditModal() {
        this.analytics.trackEvent({ category: 'Clockcard', action: 'Open edit modal' });
        this.saveAction = null;
        this.overRideEditModalTitle = this.intl.t('general.edit_worktime');
        this.showEdit = true;
    }

    @action
    async startNext() {
        this.analytics.trackEvent({ category: 'Clockcard', action: 'Start next worktime' });
        if (this.siteTooFar) return;

        const oldWorktime = this.openWorktime;
        const newWorktime = this.newWorktime;

        await oldWorktime.validate();
        await newWorktime.validate(this.validateFields);

        if (!oldWorktime.isValid) {
            this.showEdit = true;
            this.saveAction = 'startnext';
            return;
        }

        if (!newWorktime.isValid) return;

        oldWorktime.endtime = moment().format('HH:mm');
        if ('end_location' in oldWorktime) oldWorktime.end_location = await this.getLocation();
        newWorktime.starttime = moment().format('HH:mm');

        // hax to force render form again so new values of new record would be updated to fields... this wouldn't be needed
        // if fields would support ddau better .. when ddau works change to one line "this.openWorktime = newWorktime"
        this.openWorktime = null;
        next(this, () => {
            this.openWorktime = newWorktime;
        });

        this.newWorktime = this.createNewWorktime();

        // hide next worktime component
        this.closeNext();

        this.updateWorktimes();

        try {
            newWorktime.validation = 'off';

            await oldWorktime.save();
            await newWorktime.save();

            this.notifications.success(this.intl.t('general.saved'), { autoClear: true });
            this.evented.storeEvent('insert', 'worktime', oldWorktime);
        } catch (error) {
            this.error.notify(error);
            newWorktime.starttime = null;
            oldWorktime.endtime = null;
            this.newWorktime = newWorktime;
            this.openWorktime = oldWorktime;

            this.openNext(0);
        }
    }

    @action
    openNext(time = 301) {
        this.showNext = true;
        this.saveAction = null;

        // animation takes 300ms so after that we change nextContainerOnTop to true witch makes removes stop div to hidden
        // and makes startnew div to not absolute so it will change the size of the parent div
        later(
            this,
            () => {
                this.nextContainerOnTop = true;
                // if force_clockcard_dialog is on, show edit modal when we are opening "next worktime"-page
                if (this.collector.testNeeds(['products.force_clockcard_dialog'])) {
                    this.showEdit = true;
                    this.overRideEditModalTitle = this.intl.t('general.edit_last_worktime');
                }
            },
            time,
        );
    }

    @action
    closeNext() {
        this.showNext = false;
        this.nextContainerOnTop = false;
    }

    @action onFieldChange(type, field, value) {
        value = value?.target ? value.target.value : value;
        if (type === 'open') this.openWorktime[field] = value;
        else if (type === 'new') this.newWorktime[field] = value;
        if (field === 'description') {
            this.descriptionTooLong =
                value.length > 256 ? this.intl.t('validation.too_long_error') : null;
        }
        if (field === 'project') this.checkIfSiteTooFar();
    }

    @action
    closeEdit(ExtraInfo) {
        this.showEdit = false;
        // Is needed so that default pause is not lost when worktime form is opened and closed :')
        if (ExtraInfo !== 'AfterSave') this.setDefaultPause(this.openWorktime);
    }

    createNewWorktime() {
        let defaults = { date: moment().format('YYYY-MM-DD'), user: this.session.currentUser };
        if (this.collector.fieldExists('worktime', 'affects_overtime'))
            defaults.affects_overtime = 'off';
        return this.store.createRecord('worktime', defaults);
    }

    async dummy() {}

    onStoreEvent(params) {
        if (params.type === 'worktime') this.getWorktimes();
    }

    onResumeEvent(params) {
        // do not refresh if resume event was triggered last time no more than 5 minutes ago
        // params.force is used by manual refresh button and autorefresh
        if (params.duration < 5 * 60 && !params.force) return;
        this.getWorktimes();
    }

    async getWorktimes() {
        this.promise = this.store.query('worktime', {
            date:
                moment().subtract(1, 'day').format('YYYY-MM-DD') +
                '_' +
                moment().format('YYYY-MM-DD'),
            user: this.session.currentUser.id,
            sideload: true,
        });
        // Have to load multipauses also to store so some data is not undefined 🥲
        if (this.collector.testNeeds(['products.multipause']))
            await this.store.query('multipause', {
                date:
                    moment().subtract(1, 'day').format('YYYY-MM-DD') +
                    '_' +
                    moment().format('YYYY-MM-DD'),
                user: this.session.currentUser.id,
                sideload: true,
            });

        const worktimes = await this.promise;

        this.updateWorktimes();

        const openWorktime = worktimes.find((worktime) => {
            return worktime.starttime && !worktime.endtime;
        });

        if (openWorktime) {
            this.setDefaultPause(openWorktime);
            this.openWorktime = openWorktime;
            this.checkIfSiteTooFar();
        } else this.openWorktime = null;
    }

    updateWorktimes() {
        this.worktimes = this.store.peekAll('worktime').filter((worktime) => {
            return (
                worktime.date === moment().format('YYYY-MM-DD') &&
                worktime.user == this.session.currentUser
            );
        });

        const records = this.store.peekAll('worktime').filterBy('isNew', true);
        if (records.length > 0) {
            this.newWorktime = records.firstObject;
            // when reusing unsaved worktime .. date must always be today so play starts worktime for today
            this.newWorktime.date = moment().format('YYYY-MM-DD');
            if (
                this.collector.fieldExists('worktime', 'affects_overtime') &&
                !this.newWorktime.affects_overtime
            )
                this.newWorktime.affects_overtime = 'off';
        } else {
            this.newWorktime = this.createNewWorktime();
        }
        this.checkIfSiteTooFar();
    }

    // check if pause is on .. and set pause for given worktime
    setDefaultPause(worktime) {
        const pauseField = this.collector
            .fieldArray('worktime')
            .find((item) => item.name === 'pause');
        let pause = null;
        // support for worktime-groups
        if (pauseField) {
            if (typeof get(pauseField, 'features.default_value') == 'object')
                pause = get(pauseField, 'features.default_value.value');
            else pause = get(pauseField, 'features.default_value');
        }

        if ((!worktime.pause || worktime.pause == null) && pause) worktime.pause = pause;
        // Overwrite worktime default pause as null if worktime doesn't have any multipauses
        // why: because multipauses are calculated in capi and setting default value is unnecessary and messes up pauseToday
        if (worktime.multipause?.length === 0) worktime.pause = null;
    }

    async getLocation() {
        if (this.gps.status != 'located') return null;

        let location = {
            latitude: await this.gps.lockedLatitude,
            longitude: await this.gps.lockedLongitude,
            accuracy: await this.gps.lockedAccuracy,
        };

        return JSON.stringify(location);
    }

    async checkIfSiteTooFar() {
        if (!this.collector.fieldExists('project', 'force_location')) {
            this.siteTooFar = false;
            return;
        }
        if (!this.openWorktime) {
            this.siteTooFar = false;
            return;
        }
        let worktime = this.openWorktime;

        if (this.newWorktime?.project && !this.openWorktime) {
            worktime = this.newWorktime;
        }

        if (worktime.project.force_location == undefined)
            await this.store.findRecord('project', worktime.project.id, { reload: true });
        if (!worktime.project.force_location) {
            this.siteTooFar = false;
            return;
        }

        if (!this.gps.lockedPosition) {
            this.siteTooFar = true;
            return;
        }
        let projectLocation = JSON.parse(worktime.project.location_map);
        const distance = this.gps.getDistance(
            { latitude: projectLocation.latitude, longitude: projectLocation.longitude },
            {
                latitude: this.gps.lockedLatitude,
                longitude: this.gps.lockedLongitude,
            },
        );
        if (distance > this.gps.allowedDistance) {
            this.siteTooFar = true;
            return;
        }

        this.siteTooFar = false;
    }
}
